import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="qrcode-style-preview"
export default class extends Controller {
  static targets = [ "image", "form", "bgTransparentButton", "bgColorInput", "logoInput", "selectLogoInput", "removeLogoInput" ]

  static values = {
    url: String
  }

  static classes = [ "loading" ]

  connect() {
    this.updateBgColor()
  }

  async update() {
    this.imageTarget.classList.add(...this.loadingClasses)
    const formData = new FormData(this.formTarget)
    formData.delete("_method")
    const response = await post(this.urlValue, {
      body: formData,
    })

    if (response.ok) {
      response.response.blob().then(blob => {
        const url = URL.createObjectURL(blob)
        this.imageTarget.src = url
        this.imageTarget.classList.remove(...this.loadingClasses)
      })
    }
  }

  updateBgColor() {
    if (this.bgTransparentButtonTarget.checked) {
      this.bgColorInputTarget.disabled = true
    } else {
      this.bgColorInputTarget.disabled = false
    }
  }

  selectLogoFile() {
    this.selectLogoInputTargets.forEach(input => {
      input.checked = false
    })
    this.removeLogoInputTarget.checked = false
  }

  selectLogo(event) {
    event.stopPropagation()

    const url = event.currentTarget.value
    // get file from url and set to logo input
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], "logo.png", { type: "image/png" })
        const dataTransfer = new DataTransfer()
        dataTransfer.items.add(file)
        this.logoInputTarget.files = dataTransfer.files
        this.removeLogoInputTarget.checked = false
        this.update()
      })
  }

  removeLogo() {
    this.selectLogoInputTargets.forEach(input => {
      input.checked = false
    })
    this.logoInputTarget.value = null
  }
}
