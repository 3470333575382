// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import './controllers';
import './actions';
import './components';

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();
