import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="audio-form"
export default class extends Controller {
  static targets = ["tracks"]

  connect() {
    this.updateAudioTrackPositions()

    this.sortable = new Sortable(this.tracksTarget, {
      animation: 150,
      onEnd: () => this.updateAudioTrackPositions()
    })
  }

  addTrack(event) {
    const audioTrackField = document.createElement("audio-track-field")
    const file = event.target.files[0]
    const currentTrackCount = this.tracksTarget.children.length
    audioTrackField.trackName = "音轨 " + (currentTrackCount + 1)
    this.tracksTarget.appendChild(audioTrackField)
    audioTrackField.upload(file)
    this.updateAudioTrackPositions()
    event.target.value = ""
  }

  updateAudioTrackPositions() {
    this.element.querySelectorAll("audio-track-field:not([destroy])").forEach((trackField, index) => {
      trackField.position = index
    })
  }
}
