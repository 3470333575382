import {LitElement, html, css} from 'lit';
import { DirectUpload } from "@rails/activestorage"
import { destroy } from '@rails/request.js';

export class AudioTrackField extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    .container {
      display: flex;
      align-items: center;
      height: 40px;
      gap: 8px;
      border: 1px solid #ccc;
      border-radius: 8px;
      background: white;
      position: relative;
      overflow: hidden;
    }

    :host(.sortable-ghost) {
      .container {
        border-style: dashed;
        background: #f8f9fa;
      }
    }

    .col-drag-handle {
      width: 40px;
      flex-shrink: 0;
    }

    .col-trackname {
      flex-shrink: 0;
      flex-basis: 80px;

      input {
        box-sizing: border-box;
        width: 80px;
        border: none;
        padding: 6px 4px 5px;
        border-bottom: 1px solid #ccc;
        background: transparent;
        box-shadow: none;
        outline: none;
        font-size: 1rem;

        &:focus {
          padding-bottom: 4px;
          border-bottom: 2px solid #007bff;
        }
      }
    }

    .col-filename {
      flex-grow: 1;
    }

    .col-actions {
      flex-shrink: 0;
      display: flex;
      gap: 8px;
    }

    .progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      background: #007bff;
      width: 30%;
      border-radius: 2px;
    }

    .icon-btn {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      color: #737373;
      cursor: pointer;

      &:hover {
        color: #171717;
      }
    }

    .btn {
      padding: 0 8px;
      height: 40px;
      border: none;
      color: #333;
      background: transparent;
      cursor: pointer;

      &:hover {
        color: #171717;
      }
    }

    .hidden {
      display: none;
    }
  `;

  static properties = {
    trackId: {type: String, reflect: true},
    trackName: {type: String, reflect: true},
    trackFile: {type: String, reflect: true},
    filename: {type: String, reflect: true},
    byteSize: {type: Number, reflect: true},
    position: {type: Number, reflect: true},
    destroy: {type: Boolean, reflect: true},
    uploading: {type: Boolean, reflect: true},
  }

  static formAssociated = true

  constructor() {
    super()

    this.trackName = ""
    this.position = 0

    this._internals = this.attachInternals()
  }

  firstUpdated() {
    this.progressBar = this.renderRoot.querySelector(".progress-bar")
  }

  updated(changedProperties) {
    this.updateFormValue()
  }

  render() {
    return html`
      <div class="container ${this.destroy ? "hidden" : ""}">
        <div class="col-drag-handle">
          <button type="button" class="icon-btn" tabindex="-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-grip-vertical"><circle cx="9" cy="12" r="1"/><circle cx="9" cy="5" r="1"/><circle cx="9" cy="19" r="1"/><circle cx="15" cy="12" r="1"/><circle cx="15" cy="5" r="1"/><circle cx="15" cy="19" r="1"/></svg>
          </button>
        </div>
        <div class="col-trackname">
          <input type="text" .value=${this.trackName} @input=${(event) => this.trackName = event.target.value} />
        </div>
        <div class="col-filename">
          ${this.filename}
          ${this.humanSize(this.byteSize)}
        </div>
        <div class="col-actions">
          ${
            this.uploading
            ? html`
                <button type="button" class="btn" disabled>上传中...</button>
              `
            : html`
                <button type="button" class="btn" @click=${this.onUpload} tabindex="-1">重新上传</button>
                <button type="button" class="btn" @click=${this.onDestroy} tabindex="-1">删除</button>
              `
          }
        </div>
        <div class="progress-bar hidden"></div>
      </div>
    `
  }

  upload(file) {
    const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this)
    this.uploading = true

    this.filename = file.name
    this.byteSize = file.size

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        this.trackFile = blob.signed_id
        this.filename = blob.filename
        this.byteSize = blob.byte_size
        this.updateFormValue()
      }

      this.uploading = false
      if (this.progressBar) {
        this.progressBar.classList.add("hidden")
        this.progressBar.style.width = "0%"
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    if (this.progressBar) {
      this.progressBar.classList.remove("hidden")
      this.progressBar.style.width = `${(event.loaded / event.total) * 100}%`
    }
  }

  updateFormValue() {
    const data = new FormData()
    const prefix = `audio[audio_tracks_attributes][${this.position}]`

    if (this.destroy) {
      data.append(`${prefix}[id]`, this.trackId)
      data.append(`${prefix}[_destroy]`, "1")
    } else {
      if (this.trackId) {
        data.append(`${prefix}[id]`, this.trackId)
      }

      data.append(`${prefix}[name]`, this.trackName)
      data.append(`${prefix}[position]`, this.position)

      if (this.trackFile) {
        data.append(`${prefix}[file]`, this.trackFile)
      }
    }

    this._internals.setFormValue(data)
  }

  onUpload() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".mp3,.aac,.m4a,.wav,.webm"

    input.onchange = (event) => {
      this.upload(event.target.files[0])
    }

    input.click()
  }

  onDestroy() {
    if (this.trackId) {
      this.destroy = true
    } else {
      this.remove()
    }
  }

  humanSize(byteSize) {
    if (!byteSize) {
      return "0B"
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    let size = byteSize
    let unit = 0
    while (size >= 1024 && unit < units.length) {
      size /= 1024
      unit += 1
    }
    return size.toFixed(2) + units[unit]
  }
}

customElements.define('audio-track-field', AudioTrackField)
